<script>
import axios from 'axios'
export default {
  data() {
    axios.get(`https://dashboard.bigsisterprod.com/api/contacts`).then((response) => (this.contacts = response.data));
    return {
      contacts: [],
    };
  },
};
</script>
<template>
  <div>
    <section>
      <div class="container">
        <h1 class="title" data-aos="fade-right" data-aos-duration="1000">
          Contact<br />Us
        </h1>
        <div v-for="info in contacts" :key="info" class="contact">
          <div data-aos="fade-down" data-aos-duration="1000">
            <!-- <h1>We can be contacted at:</h1> -->
            <address>
              <p>{{ info.address }}</p>
              <p>{{ info.postalCode }}</p>
              <p>{{ info.country }}</p>
            </address>
          </div>

          <!-- <div data-aos="fade-down" data-aos-duration="1000">
            <h1>Or on:</h1>
            <p>Telephone: 33 1/ 43 31 98 70</p>
            <p>Mobile: 33/ 614 66 39 55</p>
          </div> -->

          <div data-aos="fade-down" data-aos-duration="1000">
            <h1>
              Alternatively, please send us an email using the link below and<br />
              we'll respond to you as soon as we can.
            </h1>
            <p>
              E-mail:
              <a :href="`mailto:` + info.mail"
                ><strong>{{ info.mail }}</strong></a
              >
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
